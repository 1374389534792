<template>
<v-col>
  <v-row>
    <v-col cols="12" sm="9" md="10" lg="7" class="mx-auto header-section" style="padding-bottom:100px;">
      <v-row>

        <v-col cols="12" class="text-center mx-auto header-section">
          <router-link to="/game/battle/menu" style="text-decoration:none;">
            <h2 class="mt-1 ml-4 primary--text">Bataille navale</h2>
          </router-link>
          <h3 class="mt-0 ml-4 primary--text text--darken-3">~ ~ ~<br>Rejoindre une partie</h3>
        </v-col>
        
        <v-col cols="12" sm="11" md="10" lg="8" class="mx-auto" v-if="myGameList != null"
                style="border-top:1px dashed #fbc02d;margin-top:60px;">
                <template v-for="game in myGameList">
                  <v-card :key="game._id" class="mt-4 pb-3" @click="join(game._id)">
                    <v-card-subtitle class="pb-0 white--text font-papyrus">
                      <v-icon  color="primary" class="mr-1 mb-2">mdi-chevron-down</v-icon>
                      <img v-if="game.player1.imgBadgeCoin != null" class="img-circle" style="margin-bottom:-7px;" 
                          :src="baseUrl() + game.player1.imgBadgeCoin" width="28">
                      <v-gravatar height="25" style="border-radius:50%;margin-bottom:-5px;" 
                                :default-img="'retro'" class="ml-1 mr-2" :email="game.player1.name"/>
                      <template>{{ game.player1.name }}</template>
                    </v-card-subtitle>
                    <v-divider class="mt-1"/>
                    <v-card-title class="py-0 mt-1">
                      <v-icon  color="primary" class="mr-2 mb-2">mdi-hand-coin</v-icon>
                      <span class="font-led primary--text">{{ game.amountBet }} PRT</span>
                      <v-icon small color="blue" class="ml-5 mr-2 mb-1">mdi-sail-boat</v-icon>
                      <span class="font-led blue--text mr-3">{{ p1NbBoatClosed(game) }}/{{ game.nbBoat }}</span>
                      <v-icon small color="blue" class="ml-5 mr-2 mb-1">mdi-sail-boat</v-icon>
                      <span class="font-led blue--text">{{ p2NbBoatClosed(game) }}/{{ game.nbBoat }}</span>
                    </v-card-title>

                    <v-toolbar color="transparent" dense elevation="0">
                      <span class="pr-1">Créé</span>
                      <time-ago :datetime="game.created" :refresh="60" long 
                                :locale="$vuetify.lang.current">
                      </time-ago>
                      <v-spacer/>
                      <v-btn @click="join(game._id)" rounded color="primary" outlined class="pl-2 pr-1">
                        Rejoindre
                        <v-icon class="ml-2">mdi-arrow-right-circle</v-icon>
                      </v-btn>
                    </v-toolbar>
                    
                  </v-card>
                </template>

                <template v-if="myGameList.length == 0">
                  <span class="font-papyrus text-center d-block mt-2">Aucune partie disponible actuellement</span>
                </template>

                <div class="my-5 pt-5"/>
                <v-btn to="/game/battle/create" block class="game-menu-btn font-main dashed" elevation="0">
                  Nouvelle partie
                </v-btn>
        </v-col>

      </v-row>
    </v-col>
  </v-row>
</v-col>
</template>

<style></style>

<script>

import axios from 'axios'
import {TimeAgo} from "vue2-timeago"
import Gravatar from 'vue-gravatar'
import router from '../../../router/router'
import core from '../../../plugins/core.js'

//import Post from '@/components/Post.vue'

export default {
  name: 'empty',
  components: { 'v-gravatar' : Gravatar, TimeAgo },
  data: () => ({
    myGameList: null
  }),
  async mounted(){
    this.$store.dispatch('app/incStat', '/game/battle/join')
    this.loadGameList()
  },
  methods: {
    loadGameList(){
      axios.get('/game/battle/to-join-games-list')
           .then((res)=>{
            if(res.data.error == false && res.data.games != null){
              this.myGameList = res.data.games
            }
           })

    },
    join(gameId){
      axios.get('/game/battle/join/'+gameId)
           .then((res)=>{
            if(res.data.error == false){
              this.$store.dispatch('auth/refreshUserData')
              router.push('/game/battle/play/'+gameId)
            }
           })
    },
    p1NbBoatClosed(game){
      let nb = 0
      game.boats1.forEach((boat)=>{ if(boat.isClosed) nb++ })
      return nb
    },
    p2NbBoatClosed(game){
      let nb = 0
      game.boats2.forEach((boat)=>{ if(boat.isClosed) nb++ })
      return nb
    },
    baseUrl(){ return core.baseUrl() },

  },
  computed: {
    
    
  }
}
</script>
